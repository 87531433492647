export const timezones = [
	{ groupname: 'Africa', value: 'Africa/Abidjan', label: 'Abidjan', notes: 'GMT +00:00' },
	{ groupname: 'Africa', value: 'Africa/Accra', label: 'Accra', notes: 'GMT +00:00' },
	{ groupname: 'Africa', value: 'Africa/Addis_Ababa', label: 'Addis Ababa', notes: 'GMT +03:00' },
	{ groupname: 'Africa', value: 'Africa/Algiers', label: 'Algiers', notes: 'GMT +01:00' },
	{ groupname: 'Africa', value: 'Africa/Asmara', label: 'Asmara', notes: 'GMT +03:00' },
	{ groupname: 'Africa', value: 'Africa/Asmera', label: 'Asmera', notes: 'GMT +03:00' },
	{ groupname: 'Africa', value: 'Africa/Bamako', label: 'Bamako', notes: 'GMT +00:00' },
	{ groupname: 'Africa', value: 'Africa/Bangui', label: 'Bangui', notes: 'GMT +01:00' },
	{ groupname: 'Africa', value: 'Africa/Banjul', label: 'Banjul', notes: 'GMT +00:00' },
	{ groupname: 'Africa', value: 'Africa/Bissau', label: 'Bissau', notes: 'GMT +00:00' },
	{ groupname: 'Africa', value: 'Africa/Blantyre', label: 'Blantyre', notes: 'GMT +02:00' },
	{ groupname: 'Africa', value: 'Africa/Brazzaville', label: 'Brazzaville', notes: 'GMT +01:00' },
	{ groupname: 'Africa', value: 'Africa/Bujumbura', label: 'Bujumbura', notes: 'GMT +02:00' },
	{ groupname: 'Africa', value: 'Africa/Cairo', label: 'Cairo', notes: 'GMT +02:00' },
	{ groupname: 'Africa', value: 'Africa/Casablanca', label: 'Casablanca', notes: 'GMT +00:00' },
	{ groupname: 'Africa', value: 'Africa/Ceuta', label: 'Ceuta', notes: 'GMT +01:00' },
	{ groupname: 'Africa', value: 'Africa/Conakry', label: 'Conakry', notes: 'GMT +00:00' },
	{ groupname: 'Africa', value: 'Africa/Dakar', label: 'Dakar', notes: 'GMT +00:00' },
	{ groupname: 'Africa', value: 'Africa/Dar_es_Salaam', label: 'Dar es_Salaam', notes: 'GMT +03:00' },
	{ groupname: 'Africa', value: 'Africa/Djibouti', label: 'Djibouti', notes: 'GMT +03:00' },
	{ groupname: 'Africa', value: 'Africa/Douala', label: 'Douala', notes: 'GMT +01:00' },
	{ groupname: 'Africa', value: 'Africa/El_Aaiun', label: 'El Aaiun', notes: 'GMT +00:00' },
	{ groupname: 'Africa', value: 'Africa/Freetown', label: 'Freetown', notes: 'GMT +00:00' },
	{ groupname: 'Africa', value: 'Africa/Gaborone', label: 'Gaborone', notes: 'GMT +02:00' },
	{ groupname: 'Africa', value: 'Africa/Harare', label: 'Harare', notes: 'GMT +02:00' },
	{ groupname: 'Africa', value: 'Africa/Johannesburg', label: 'Johannesburg', notes: 'GMT +02:00' },
	{ groupname: 'Africa', value: 'Africa/Juba', label: 'Juba', notes: 'GMT +03:00' },
	{ groupname: 'Africa', value: 'Africa/Kampala', label: 'Kampala', notes: 'GMT +03:00' },
	{ groupname: 'Africa', value: 'Africa/Khartoum', label: 'Khartoum', notes: 'GMT +03:00' },
	{ groupname: 'Africa', value: 'Africa/Kigali', label: 'Kigali', notes: 'GMT +02:00' },
	{ groupname: 'Africa', value: 'Africa/Kinshasa', label: 'Kinshasa', notes: 'GMT +01:00' },
	{ groupname: 'Africa', value: 'Africa/Lagos', label: 'Lagos', notes: 'GMT +01:00' },
	{ groupname: 'Africa', value: 'Africa/Libreville', label: 'Libreville', notes: 'GMT +01:00' },
	{ groupname: 'Africa', value: 'Africa/Lome', label: 'Lome', notes: 'GMT +00:00' },
	{ groupname: 'Africa', value: 'Africa/Luanda', label: 'Luanda', notes: 'GMT +01:00' },
	{ groupname: 'Africa', value: 'Africa/Lubumbashi', label: 'Lubumbashi', notes: 'GMT +02:00' },
	{ groupname: 'Africa', value: 'Africa/Lusaka', label: 'Lusaka', notes: 'GMT +02:00' },
	{ groupname: 'Africa', value: 'Africa/Malabo', label: 'Malabo', notes: 'GMT +01:00' },
	{ groupname: 'Africa', value: 'Africa/Maputo', label: 'Maputo', notes: 'GMT +02:00' },
	{ groupname: 'Africa', value: 'Africa/Maseru', label: 'Maseru', notes: 'GMT +02:00' },
	{ groupname: 'Africa', value: 'Africa/Mbabane', label: 'Mbabane', notes: 'GMT +02:00' },
	{ groupname: 'Africa', value: 'Africa/Mogadishu', label: 'Mogadishu', notes: 'GMT +03:00' },
	{ groupname: 'Africa', value: 'Africa/Monrovia', label: 'Monrovia', notes: 'GMT +00:00' },
	{ groupname: 'Africa', value: 'Africa/Nairobi', label: 'Nairobi', notes: 'GMT +03:00' },
	{ groupname: 'Africa', value: 'Africa/Ndjamena', label: 'Ndjamena', notes: 'GMT +01:00' },
	{ groupname: 'Africa', value: 'Africa/Niamey', label: 'Niamey', notes: 'GMT +01:00' },
	{ groupname: 'Africa', value: 'Africa/Nouakchott', label: 'Nouakchott', notes: 'GMT +00:00' },
	{ groupname: 'Africa', value: 'Africa/Ouagadougou', label: 'Ouagadougou', notes: 'GMT +00:00' },
	{ groupname: 'Africa', value: 'Africa/Porto-Nov', label: 'Porto-Novo', notes: 'GMT +01:00' },
	{ groupname: 'Africa', value: 'Africa/Sao_Tome', label: 'Sao Tome', notes: 'GMT +00:00' },
	{ groupname: 'Africa', value: 'Africa/Timbuktu', label: 'Timbuktu', notes: 'GMT +00:00' },
	{ groupname: 'Africa', value: 'Africa/Tripoli', label: 'Tripoli', notes: 'GMT +01:00' },
	{ groupname: 'Africa', value: 'Africa/Tunis', label: 'Tunis', notes: 'GMT +01:00' },
	{ groupname: 'Africa', value: 'Africa/Windhoek', label: 'Windhoek', notes: 'GMT +01:00' },
	{ groupname: 'America', value: 'America/Adak', label: 'Adak', notes: '-10:00' },
	{ groupname: 'America', value: 'America/Anchorage', label: 'Anchorage', notes: '-09:00' },
	{ groupname: 'America', value: 'America/Anguilla', label: 'Anguilla', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Antigua', label: 'Antigua', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Araguaina', label: 'Araguaina', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Argentina/Buenos_Aires', label: 'Buenos Aires, Argentina', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Argentina/Catamarca', label: 'Catamarca, Argentina', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Argentina/ComodRivadavia', label: 'ComodRivadavia, Argentina',notes: '-03:00' },
	{ groupname: 'America', value: 'America/Argentina/Cordoba', label: 'Cordoba, Argentina', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Argentina/Jujuy', label: 'Jujuy, Argentina', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Argentina/La_Rioja', label: 'La Rioja, Argentina', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Argentina/Mendoza', label: 'Mendoza, Argentina', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Argentina/Rio_Gallegos', label: 'Rio Gallegos, Argentina',notes: '-03:00' },
	{ groupname: 'America', value: 'America/Argentina/Salta', label: 'Salta, Argentina', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Argentina/San_Juan', label: 'San Juan, Argentina', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Argentina/San_Luis', label: 'San Luis, Argentina', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Argentina/Tucuman', label: 'Tucuman, Argentina', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Argentina/Ushuaia', label: 'Ushuaia, Argentina', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Aruba', label: 'Aruba', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Asuncion', label: 'Asuncion', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Atikokan', label: 'Atikokan', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Atka', label: 'Atka', notes: '-10:00' },
	{ groupname: 'America', value: 'America/Bahia', label: 'Bahia', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Bahia_Banderas', label: 'Bahia Banderas', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Barbados', label: 'Barbados', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Belem', label: 'Belem', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Belize', label: 'Belize', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Blanc-Sablon', label: 'Blanc-Sablon', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Boa_Vista', label: 'Boa Vista', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Bogota', label: 'Bogota', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Boise', label: 'Boise', notes: '-07:00' },
	{ groupname: 'America', value: 'America/Buenos_Aires', label: 'Buenos Aires', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Cambridge_Bay', label: 'Cambridge Bay', notes: '-07:00' },
	{ groupname: 'America', value: 'America/Campo_Grande', label: 'Campo Grande', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Cancun', label: 'Cancun', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Caracas', label: 'Caracas', notes: '-04:30' },
	{ groupname: 'America', value: 'America/Catamarca', label: 'Catamarca', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Cayenne', label: 'Cayenne', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Cayman', label: 'Cayman', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Chicago', label: 'Chicago', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Chihuahua', label: 'Chihuahua', notes: '-07:00' },
	{ groupname: 'America', value: 'America/Coral_Harbour', label: 'Coral Harbour', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Cordoba', label: 'Cordoba', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Costa_Rica', label: 'Costa Rica', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Creston', label: 'Creston', notes: '-07:00' },
	{ groupname: 'America', value: 'America/Cuiaba', label: 'Cuiaba', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Curacao', label: 'Curacao', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Danmarkshavn', label: 'Danmarkshavn', notes: '+00:00' },
	{ groupname: 'America', value: 'America/Dawson', label: 'Dawson', notes: '-08:00' },
	{ groupname: 'America', value: 'America/Dawson_Creek', label: 'Dawson Creek', notes: '-07:00' },
	{ groupname: 'America', value: 'America/Denver', label: 'Denver', notes: '-07:00' },
	{ groupname: 'America', value: 'America/Detroit', label: 'Detroit', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Dominica', label: 'Dominica', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Edmonton', label: 'Edmonton', notes: '-07:00' },
	{ groupname: 'America', value: 'America/Eirunepe', label: 'Eirunepe', notes: '-05:00' },
	{ groupname: 'America', value: 'America/El_Salvador', label: 'El Salvador', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Ensenada', label: 'Ensenada', notes: '-08:00' },
	{ groupname: 'America', value: 'America/Fort_Wayne', label: 'Fort Wayne', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Fortaleza', label: 'Fortaleza', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Glace_Bay', label: 'Glace Bay', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Godthab', label: 'Godthab', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Goose_Bay', label: 'Goose Bay', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Grand_Turk', label: 'Grand Turk', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Grenada', label: 'Grenada', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Guadeloupe', label: 'Guadeloupe', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Guatemala', label: 'Guatemala', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Guayaquil', label: 'Guayaquil', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Guyana', label: 'Guyana', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Halifax', label: 'Halifax', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Havana', label: 'Havana', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Hermosillo', label: 'Hermosillo', notes: '-07:00' },
	{ groupname: 'America', value: 'America/Indiana/Indianapolis', label: 'Indianapolis, Indiana',notes: '-05:00' },
	{ groupname: 'America', value: 'America/Indiana/Knox', label: 'Knox, Indiana', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Indiana/Marengo', label: 'Marengo, Indiana', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Indiana/Petersburg', label: 'Petersburg, Indiana', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Indiana/Tell_City', label: 'Tell City, Indiana', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Indiana/Valparaiso', label: 'Valparaiso, Indiana', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Indiana/Vevay', label: 'Vevay, Indiana', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Indiana/Vincennes', label: 'Vincennes, Indiana', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Indiana/Winamac', label: 'Winamac, Indiana', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Indianapolis', label: 'Indianapolis', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Inuvik', label: 'Inuvik', notes: '-07:00' },
	{ groupname: 'America', value: 'America/Iqaluit', label: 'Iqaluit', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Jamaica', label: 'Jamaica', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Jujuy', label: 'Jujuy', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Juneau', label: 'Juneau', notes: '-09:00' },
	{ groupname: 'America', value: 'America/Kentucky/Louisville', label: 'Louisville, Kentucky', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Kentucky/Monticello', label: 'Monticello, Kentucky', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Knox_IN', label: 'Knox IN', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Kralendijk', label: 'Kralendijk', notes: '-04:00' },
	{ groupname: 'America', value: 'America/La_Paz', label: 'La Paz', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Lima', label: 'Lima', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Los_Angeles', label: 'Los Angeles', notes: '-08:00' },
	{ groupname: 'America', value: 'America/Louisville', label: 'Louisville', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Lower_Princes', label: 'Lower Princes', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Maceio', label: 'Maceio', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Managua', label: 'Managua', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Manaus', label: 'Manaus', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Marigot', label: 'Marigot', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Martinique', label: 'Martinique', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Matamoros', label: 'Matamoros', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Mazatlan', label: 'Mazatlan', notes: '-07:00' },
	{ groupname: 'America', value: 'America/Mendoza', label: 'Mendoza', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Menominee', label: 'Menominee', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Merida', label: 'Merida', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Metlakatla', label: 'Metlakatla', notes: '-08:00' },
	{ groupname: 'America', value: 'America/Mexico_City', label: 'Mexico City', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Miquelon', label: 'Miquelon', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Moncton', label: 'Moncton', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Monterrey', label: 'Monterrey', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Montevideo', label: 'Montevideo', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Montreal', label: 'Montreal', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Montserrat', label: 'Montserrat', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Nassau', label: 'Nassau', notes: '-05:00' },
	{ groupname: 'America', value: 'America/New_York', label: 'New York', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Nipigon', label: 'Nipigon', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Nome', label: 'Nome', notes: '-09:00' },
	{ groupname: 'America', value: 'America/Noronha', label: 'Noronha', notes: '-02:00' },
	{ groupname: 'America', value: 'America/North_Dakota/Beulah', label: 'Beulah, North Dakota', notes: '-06:00' },
	{ groupname: 'America', value: 'America/North_Dakota/Center', label: 'Center, North Dakota', notes: '-06:00' },
	{ groupname: 'America', value: 'America/North_Dakota/New_Salem', label: 'New Salem, North Dakota', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Ojinaga', label: 'Ojinaga', notes: '-07:00' },
	{ groupname: 'America', value: 'America/Panama', label: 'Panama', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Pangnirtung', label: 'Pangnirtung', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Paramaribo', label: 'Paramaribo', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Phoenix', label: 'Phoenix', notes: '-07:00' },
	{ groupname: 'America', value: 'America/Port-au-Prince', label: 'Port-au-Prince', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Port_of_Spain', label: 'Port of Spain', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Porto_Acre', label: 'Porto Acre', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Porto_Velho', label: 'Porto Velho', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Puerto_Rico', label: 'Puerto Rico', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Rainy_River', label: 'Rainy River', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Rankin_Inlet', label: 'Rankin Inlet', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Recife', label: 'Recife', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Regina', label: 'Regina', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Resolute', label: 'Resolute', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Rio_Branco', label: 'Rio Branco', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Rosario', label: 'Rosario', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Santa_Isabel', label: 'Santa Isabel', notes: '-08:00' },
	{ groupname: 'America', value: 'America/Santarem', label: 'Santarem', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Santiago', label: 'Santiago', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Santo_Domingo', label: 'Santo Domingo', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Sao_Paulo', label: 'Sao Paulo', notes: '-03:00' },
	{ groupname: 'America', value: 'America/Scoresbysund', label: 'Scoresbysund', notes: '-01:00' },
	{ groupname: 'America', value: 'America/Shiprock', label: 'Shiprock', notes: '-07:00' },
	{ groupname: 'America', value: 'America/Sitka', label: 'Sitka', notes: '-09:00' },
	{ groupname: 'America', value: 'America/St_Barthelemy', label: 'St Barthelemy', notes: '-04:00' },
	{ groupname: 'America', value: 'America/St_Johns', label: 'St Johns', notes: '-03:30' },
	{ groupname: 'America', value: 'America/St_Kitts', label: 'St Kitts', notes: '-04:00' },
	{ groupname: 'America', value: 'America/St_Lucia', label: 'St Lucia', notes: '-04:00' },
	{ groupname: 'America', value: 'America/St_Thomas', label: 'St Thomas', notes: '-04:00' },
	{ groupname: 'America', value: 'America/St_Vincent', label: 'St Vincent', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Swift_Current', label: 'Swift Current', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Tegucigalpa', label: 'Tegucigalpa', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Thule', label: 'Thule', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Thunder_Bay', label: 'Thunder Bay', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Tijuana', label: 'Tijuana', notes: '-08:00' },
	{ groupname: 'America', value: 'America/Toronto', label: 'Toronto', notes: '-05:00' },
	{ groupname: 'America', value: 'America/Tortola', label: 'Tortola', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Vancouver', label: 'Vancouver', notes: '-08:00' },
	{ groupname: 'America', value: 'America/Virgin', label: 'Virgin', notes: '-04:00' },
	{ groupname: 'America', value: 'America/Whitehorse', label: 'Whitehorse', notes: '-08:00' },
	{ groupname: 'America', value: 'America/Winnipeg', label: 'Winnipeg', notes: '-06:00' },
	{ groupname: 'America', value: 'America/Yakutat', label: 'Yakutat', notes: '-09:00' },
	{ groupname: 'America', value: 'America/Yellowknife', label: 'Yellowknife', notes:  '-07:00' },
	{ groupname: 'Antarctica', value: 'Antarctica/Casey', label: 'Casey', notes: '+11:00' },
	{ groupname: 'Antarctica', value: 'Antarctica/Davis', label: 'Davis', notes: '+05:00' },
	{ groupname: 'Antarctica', value: 'Antarctica/DumontDUrville', label: 'DumontDUrville', notes: '+10:00' },
	{ groupname: 'Antarctica', value: 'Antarctica/Macquarie', label: 'Macquarie', notes: '+11:00' },
	{ groupname: 'Antarctica', value: 'Antarctica/Mawson', label: 'Mawson', notes: '+05:00' },
	{ groupname: 'Antarctica', value: 'Antarctica/McMurdo', label: 'McMurdo', notes: '+12:00' },
	{ groupname: 'Antarctica', value: 'Antarctica/Palmer', label: 'Palmer', notes: '-04:00' },
	{ groupname: 'Antarctica', value: 'Antarctica/Rothera', label: 'Rothera', notes: '-03:00' },
	{ groupname: 'Antarctica', value: 'Antarctica/South_Pole', label: 'South Pole', notes: '+12:00' },
	{ groupname: 'Antarctica', value: 'Antarctica/Syowa', label: 'Syowa', notes: '+03:00' },
	{ groupname: 'Antarctica', value: 'Antarctica/Troll', label: 'Troll', notes: '+00:00' },
	{ groupname: 'Antarctica', value: 'Antarctica/Vostok', label: 'Vostok', notes: '+06:00' },
	{ groupname: 'Asia', value: 'Asia/Aden', label: 'Aden', notes: '+03:00' },
	{ groupname: 'Asia', value: 'Asia/Almaty', label: 'Almaty', notes: '+06:00' },
	{ groupname: 'Asia', value: 'Asia/Amman', label: 'Amman', notes: '+02:00' },
	{ groupname: 'Asia', value: 'Asia/Anadyr', label: 'Anadyr', notes: '+12:00' },
	{ groupname: 'Asia', value: 'Asia/Aqtau', label: 'Aqtau', notes: '+05:00' },
	{ groupname: 'Asia', value: 'Asia/Aqtobe', label: 'Aqtobe', notes: '+05:00' },
	{ groupname: 'Asia', value: 'Asia/Ashgabat', label: 'Ashgabat', notes: '+05:00' },
	{ groupname: 'Asia', value: 'Asia/Ashkhabad', label: 'Ashkhabad', notes: '+05:00' },
	{ groupname: 'Asia', value: 'Asia/Baghdad', label: 'Baghdad', notes: '+03:00' },
	{ groupname: 'Asia', value: 'Asia/Bahrain', label: 'Bahrain', notes: '+03:00' },
	{ groupname: 'Asia', value: 'Asia/Baku', label: 'Baku', notes: '+04:00' },
	{ groupname: 'Asia', value: 'Asia/Bangkok', label: 'Bangkok', notes: '+07:00' },
	{ groupname: 'Asia', value: 'Asia/Beirut', label: 'Beirut', notes: '+02:00' },
	{ groupname: 'Asia', value: 'Asia/Bishkek', label: 'Bishkek', notes: '+06:00' },
	{ groupname: 'Asia', value: 'Asia/Brunei', label: 'Brunei', notes: '+08:00' },
	{ groupname: 'Asia', value: 'Asia/Calcutta', label: 'Calcutta', notes: '+05:30' },
	{ groupname: 'Asia', value: 'Asia/Choibalsan', label: 'Choibalsan', notes: '+08:00' },
	{ groupname: 'Asia', value: 'Asia/Chongqing', label: 'Chongqing', notes: '+08:00' },
	{ groupname: 'Asia', value: 'Asia/Chungking', label: 'Chungking', notes: '+08:00' },
	{ groupname: 'Asia', value: 'Asia/Colombo', label: 'Colombo', notes: '+05:30' },
	{ groupname: 'Asia', value: 'Asia/Dacca', label: 'Dacca', notes: '+06:00' },
	{ groupname: 'Asia', value: 'Asia/Damascus', label: 'Damascus', notes: '+02:00' },
	{ groupname: 'Asia', value: 'Asia/Dhaka', label: 'Dhaka', notes: '+06:00' },
	{ groupname: 'Asia', value: 'Asia/Dili', label: 'Dili', notes: '+09:00' },
	{ groupname: 'Asia', value: 'Asia/Dubai', label: 'Dubai', notes: '+04:00' },
	{ groupname: 'Asia', value: 'Asia/Dushanbe', label: 'Dushanbe', notes: '+05:00' },
	{ groupname: 'Asia', value: 'Asia/Gaza', label: 'Gaza', notes: '+02:00' },
	{ groupname: 'Asia', value: 'Asia/Harbin', label: 'Harbin', notes: '+08:00' },
	{ groupname: 'Asia', value: 'Asia/Hebron', label: 'Hebron', notes: '+02:00' },
	{ groupname: 'Asia', value: 'Asia/Ho_Chi_Minh', label: 'Ho Chi_Minh', notes: '+07:00' },
	{ groupname: 'Asia', value: 'Asia/Hong_Kong', label: 'Hong Kong', notes: '+08:00' },
	{ groupname: 'Asia', value: 'Asia/Hovd', label: 'Hovd', notes: '+07:00' },
	{ groupname: 'Asia', value: 'Asia/Irkutsk', label: 'Irkutsk', notes: '+08:00' },
	{ groupname: 'Asia', value: 'Asia/Istanbul', label: 'Istanbul', notes: '+02:00' },
	{ groupname: 'Asia', value: 'Asia/Jakarta', label: 'Jakarta', notes: '+07:00' },
	{ groupname: 'Asia', value: 'Asia/Jayapura', label: 'Jayapura', notes: '+09:00' },
	{ groupname: 'Asia', value: 'Asia/Jerusalem', label: 'Jerusalem', notes: '+02:00' },
	{ groupname: 'Asia', value: 'Asia/Kabul', label: 'Kabul', notes: '+04:30' },
	{ groupname: 'Asia', value: 'Asia/Kamchatka', label: 'Kamchatka', notes: '+12:00' },
	{ groupname: 'Asia', value: 'Asia/Karachi', label: 'Karachi', notes: '+05:00' },
	{ groupname: 'Asia', value: 'Asia/Kashgar', label: 'Kashgar', notes: '+08:00' },
	{ groupname: 'Asia', value: 'Asia/Kathmandu', label: 'Kathmandu', notes: '+05:45' },
	{ groupname: 'Asia', value: 'Asia/Katmandu', label: 'Katmandu', notes: '+05:45' },
	{ groupname: 'Asia', value: 'Asia/Khandyga', label: 'Khandyga', notes: '+09:00' },
	{ groupname: 'Asia', value: 'Asia/Kolkata', label: 'Kolkata', notes: '+05:30' },
	{ groupname: 'Asia', value: 'Asia/Krasnoyarsk', label: 'Krasnoyarsk', notes: '+07:00' },
	{ groupname: 'Asia', value: 'Asia/Kuala_Lumpur', label: 'Kuala Lumpur', notes: '+08:00' },
	{ groupname: 'Asia', value: 'Asia/Kuching', label: 'Kuching', notes: '+08:00' },
	{ groupname: 'Asia', value: 'Asia/Kuwait', label: 'Kuwait', notes: '+03:00' },
	{ groupname: 'Asia', value: 'Asia/Macao', label: 'Macao', notes: '+08:00' },
	{ groupname: 'Asia', value: 'Asia/Macau', label: 'Macau', notes: '+08:00' },
	{ groupname: 'Asia', value: 'Asia/Magadan', label: 'Magadan', notes: '+10:00' },
	{ groupname: 'Asia', value: 'Asia/Makassar', label: 'Makassar', notes: '+08:00' },
	{ groupname: 'Asia', value: 'Asia/Manila', label: 'Manila', notes: '+08:00' },
	{ groupname: 'Asia', value: 'Asia/Muscat', label: 'Muscat', notes: '+04:00' },
	{ groupname: 'Asia', value: 'Asia/Nicosia', label: 'Nicosia', notes: '+02:00' },
	{ groupname: 'Asia', value: 'Asia/Novokuznetsk', label: 'Novokuznetsk', notes: '+07:00' },
	{ groupname: 'Asia', value: 'Asia/Novosibirsk', label: 'Novosibirsk', notes: '+06:00' },
	{ groupname: 'Asia', value: 'Asia/Omsk', label: 'Omsk', notes: '+06:00' },
	{ groupname: 'Asia', value: 'Asia/Oral', label: 'Oral', notes: '+05:00' },
	{ groupname: 'Asia', value: 'Asia/Phnom_Penh', label: 'Phnom Penh', notes: '+07:00' },
	{ groupname: 'Asia', value: 'Asia/Pontianak', label: 'Pontianak', notes: '+07:00' },
	{ groupname: 'Asia', value: 'Asia/Pyongyang', label: 'Pyongyang', notes: '+09:00' },
	{ groupname: 'Asia', value: 'Asia/Qatar', label: 'Qatar', notes: '+03:00' },
	{ groupname: 'Asia', value: 'Asia/Qyzylorda', label: 'Qyzylorda', notes: '+06:00' },
	{ groupname: 'Asia', value: 'Asia/Rangoon', label: 'Rangoon', notes: '+06:30' },
	{ groupname: 'Asia', value: 'Asia/Riyadh', label: 'Riyadh', notes: '+03:00' },
	{ groupname: 'Asia', value: 'Asia/Saigon', label: 'Saigon', notes: '+07:00' },
	{ groupname: 'Asia', value: 'Asia/Sakhalin', label: 'Sakhalin', notes: '+11:00' },
	{ groupname: 'Asia', value: 'Asia/Samarkand', label: 'Samarkand', notes: '+05:00' },
	{ groupname: 'Asia', value: 'Asia/Seoul', label: 'Seoul', notes: '+09:00' },
	{ groupname: 'Asia', value: 'Asia/Shanghai', label: 'Shanghai', notes: '+08:00' },
	{ groupname: 'Asia', value: 'Asia/Singapore', label: 'Singapore', notes: '+08:00' },
	{ groupname: 'Asia', value: 'Asia/Taipei', label: 'Taipei', notes: '+08:00' },
	{ groupname: 'Asia', value: 'Asia/Tashkent', label: 'Tashkent', notes: '+05:00' },
	{ groupname: 'Asia', value: 'Asia/Tbilisi', label: 'Tbilisi', notes: '+04:00' },
	{ groupname: 'Asia', value: 'Asia/Tehran', label: 'Tehran', notes: '+03:30' },
	{ groupname: 'Asia', value: 'Asia/Tel_Aviv', label: 'Tel Aviv', notes: '+02:00' },
	{ groupname: 'Asia', value: 'Asia/Thimbu', label: 'Thimbu', notes: '+06:00' },
	{ groupname: 'Asia', value: 'Asia/Thimphu', label: 'Thimphu', notes: '+06:00' },
	{ groupname: 'Asia', value: 'Asia/Tokyo', label: 'Tokyo', notes: '+09:00' },
	{ groupname: 'Asia', value: 'Asia/Ujung_Pandang', label: 'Ujung Pandang', notes: '+08:00' },
	{ groupname: 'Asia', value: 'Asia/Ulaanbaatar', label: 'Ulaanbaatar', notes: '+08:00' },
	{ groupname: 'Asia', value: 'Asia/Ulan_Bator', label: 'Ulan Bator', notes: '+08:00' },
	{ groupname: 'Asia', value: 'Asia/Urumqi', label: 'Urumqi', notes: '+08:00' },
	{ groupname: 'Asia', value: 'Asia/Ust-Nera', label: 'Ust-Nera', notes: '+10:00' },
	{ groupname: 'Asia', value: 'Asia/Vientiane', label: 'Vientiane', notes: '+07:00' },
	{ groupname: 'Asia', value: 'Asia/Vladivostok', label: 'Vladivostok', notes: '+10:00' },
	{ groupname: 'Asia', value: 'Asia/Yakutsk', label: 'Yakutsk', notes: '+09:00' },
	{ groupname: 'Asia', value: 'Asia/Yekaterinburg', label: 'Yekaterinburg', notes: '+05:00' },
	{ groupname: 'Asia', value: 'Asia/Yerevan', label: 'Yerevan', notes: '+04:00' },
	{ groupname: 'Australia', value: 'Australia/ACT', label: 'ACT', notes: '+10:00' },
	{ groupname: 'Australia', value: 'Australia/Adelaide', label: 'Adelaide', notes: '+09:30' },
	{ groupname: 'Australia', value: 'Australia/Brisbane', label: 'Brisbane', notes: '+10:00' },
	{ groupname: 'Australia', value: 'Australia/Broken_Hill', label: 'Broken Hill', notes: '+09:30' },
	{ groupname: 'Australia', value: 'Australia/Canberra', label: 'Canberra', notes: '+10:00' },
	{ groupname: 'Australia', value: 'Australia/Currie', label: 'Currie', notes: '+10:00' },
	{ groupname: 'Australia', value: 'Australia/Darwin', label: 'Darwin', notes: '+09:30' },
	{ groupname: 'Australia', value: 'Australia/Eucla', label: 'Eucla', notes: '+08:45' },
	{ groupname: 'Australia', value: 'Australia/Hobart', label: 'Hobart', notes: '+10:00' },
	{ groupname: 'Australia', value: 'Australia/LHI', label: 'LHI', notes: '+10:30' },
	{ groupname: 'Australia', value: 'Australia/Lindeman', label: 'Lindeman', notes: '+10:00' },
	{ groupname: 'Australia', value: 'Australia/Lord_Howe', label: 'Lord Howe', notes: '+10:30' },
	{ groupname: 'Australia', value: 'Australia/Melbourne', label: 'Melbourne', notes: '+10:00' },
	{ groupname: 'Australia', value: 'Australia/NSW', label: 'NSW', notes: '+10:00' },
	{ groupname: 'Australia', value: 'Australia/North', label: 'North', notes: '+09:30' },
	{ groupname: 'Australia', value: 'Australia/Perth', label: 'Perth', notes: '+08:00' },
	{ groupname: 'Australia', value: 'Australia/Queensland', label: 'Queensland', notes: '+10:00' },
	{ groupname: 'Australia', value: 'Australia/South', label: 'South', notes: '+09:30' },
	{ groupname: 'Australia', value: 'Australia/Sydney', label: 'Sydney', notes: '+10:00' },
	{ groupname: 'Australia', value: 'Australia/Tasmania', label: 'Tasmania', notes: '+10:00' },
	{ groupname: 'Australia', value: 'Australia/Victoria', label: 'Victoria', notes: '+10:00' },
	{ groupname: 'Australia', value: 'Australia/West', label: 'West', notes: '+08:00' },
	{ groupname: 'Australia', value: 'Australia/Yancowinna', label: 'Yancowinna', notes: '+09:30' },
	{ groupname: 'Brazil', value: 'Brazil/Acre', label: 'Acre', notes: '-05:00' },
	{ groupname: 'Brazil', value: 'Brazil/DeNoronha', label: 'DeNoronha', notes: '-02:00' },
	{ groupname: 'Brazil', value: 'Brazil/East', label: 'East', notes: '-03:00' },
	{ groupname: 'Brazil', value: 'Brazil/West', label: 'West', notes: '-04:00' },
	{ groupname: 'Canada', value: 'Canada/Atlantic', label: 'Atlantic', notes: '-04:00' },
	{ groupname: 'Canada', value: 'Canada/Central', label: 'Central', notes: '-06:00' },
	{ groupname: 'Canada', value: 'Canada/East-Saskatchewan', label: 'East Saskatchewan', notes: '-06:00' },
	{ groupname: 'Canada', value: 'Canada/Eastern', label: 'Eastern', notes: '-05:00' },
	{ groupname: 'Canada', value: 'Canada/Mountain', label: 'Mountain', notes: '-07:00' },
	{ groupname: 'Canada', value: 'Canada/Newfoundland', label: 'Newfoundland', notes: '-03:30' },
	{ groupname: 'Canada', value: 'Canada/Pacific', label: 'Pacific', notes: '-08:00' },
	{ groupname: 'Canada', value: 'Canada/Saskatchewan', label: 'Saskatchewan', notes: '-06:00' },
	{ groupname: 'Canada', value: 'Canada/Yukon', label: 'Yukon', notes: '-08:00' },
	{ groupname: 'Chile', value: 'Chile/Continental', label: 'Continental', notes: '-04:00' },
	{ groupname: 'Chile', value: 'Chile/EasterIsland', label: 'EasterIsland', notes: '-06:00' },
	{ groupname: 'Europe', value: 'Europe/Amsterdam', label: 'Amsterdam', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Andorra', label: 'Andorra', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Athens', label: 'Athens', notes: '+02:00' },
	{ groupname: 'Europe', value: 'Europe/Belfast', label: 'Belfast', notes: '+00:00' },
	{ groupname: 'Europe', value: 'Europe/Belgrade', label: 'Belgrade', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Berlin', label: 'Berlin', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Bratislava', label: 'Bratislava', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Brussels', label: 'Brussels', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Bucharest', label: 'Bucharest', notes: '+02:00' },
	{ groupname: 'Europe', value: 'Europe/Budapest', label: 'Budapest', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Busingen', label: 'Busingen', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Chisinau', label: 'Chisinau', notes: '+02:00' },
	{ groupname: 'Europe', value: 'Europe/Copenhagen', label: 'Copenhagen', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Dublin', label: 'Dublin', notes: '+00:00' },
	{ groupname: 'Europe', value: 'Europe/Gibraltar', label: 'Gibraltar', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Guernsey', label: 'Guernsey', notes: '+00:00' },
	{ groupname: 'Europe', value: 'Europe/Helsinki', label: 'Helsinki', notes: '+02:00' },
	{ groupname: 'Europe', value: 'Europe/Isle_of_Man', label: 'Isle of_Man', notes: '+00:00' },
	{ groupname: 'Europe', value: 'Europe/Istanbul', label: 'Istanbul', notes: '+02:00' },
	{ groupname: 'Europe', value: 'Europe/Jersey', label: 'Jersey', notes: '+00:00' },
	{ groupname: 'Europe', value: 'Europe/Kaliningrad', label: 'Kaliningrad', notes: '+02:00' },
	{ groupname: 'Europe', value: 'Europe/Kiev', label: 'Kiev', notes: '+02:00' },
	{ groupname: 'Europe', value: 'Europe/Lisbon', label: 'Lisbon', notes: '+00:00' },
	{ groupname: 'Europe', value: 'Europe/Ljubljana', label: 'Ljubljana', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/London', label: 'London', notes: '+00:00' },
	{ groupname: 'Europe', value: 'Europe/Luxembourg', label: 'Luxembourg', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Madrid', label: 'Madrid', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Malta', label: 'Malta', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Mariehamn', label: 'Mariehamn', notes: '+02:00' },
	{ groupname: 'Europe', value: 'Europe/Minsk', label: 'Minsk', notes: '+03:00' },
	{ groupname: 'Europe', value: 'Europe/Monaco', label: 'Monaco', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Moscow', label: 'Moscow', notes: '+03:00' },
	{ groupname: 'Europe', value: 'Europe/Nicosia', label: 'Nicosia', notes: '+02:00' },
	{ groupname: 'Europe', value: 'Europe/Oslo', label: 'Oslo', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Paris', label: 'Paris', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Podgorica', label: 'Podgorica', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Prague', label: 'Prague', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Riga', label: 'Riga', notes: '+02:00' },
	{ groupname: 'Europe', value: 'Europe/Rome', label: 'Rome', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Samara', label: 'Samara', notes: '+04:00' },
	{ groupname: 'Europe', value: 'Europe/San_Marino', label: 'San Marino', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Sarajevo', label: 'Sarajevo', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Simferopol', label: 'Simferopol', notes: '+03:00' },
	{ groupname: 'Europe', value: 'Europe/Skopje', label: 'Skopje', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Sofia', label: 'Sofia', notes: '+02:00' },
	{ groupname: 'Europe', value: 'Europe/Stockholm', label: 'Stockholm', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Tallinn', label: 'Tallinn', notes: '+02:00' },
	{ groupname: 'Europe', value: 'Europe/Tirane', label: 'Tirane', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Tiraspol', label: 'Tiraspol', notes: '+02:00' },
	{ groupname: 'Europe', value: 'Europe/Uzhgorod', label: 'Uzhgorod', notes: '+02:00' },
	{ groupname: 'Europe', value: 'Europe/Vaduz', label: 'Vaduz', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Vatican', label: 'Vatican', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Vienna', label: 'Vienna', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Vilnius', label: 'Vilnius', notes: '+02:00' },
	{ groupname: 'Europe', value: 'Europe/Volgograd', label: 'Volgograd', notes: '+03:00' },
	{ groupname: 'Europe', value: 'Europe/Warsaw', label: 'Warsaw', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Zagreb', label: 'Zagreb', notes: '+01:00' },
	{ groupname: 'Europe', value: 'Europe/Zaporozhye', label: 'Zaporozhye', notes: '+02:00' },
	{ groupname: 'Europe', value: 'Europe/Zurich', label: 'Zurich', notes: '+01:00' },
	{ groupname: 'Indian Ocean', value: 'Indian/Antananarivo', label: 'Antananarivo', notes: '+03:00' },
	{ groupname: 'Indian Ocean', value: 'Indian/Chagos', label: 'Chagos', notes: '+06:00' },
	{ groupname: 'Indian Ocean', value: 'Indian/Christmas', label: 'Christmas', notes: '+07:00' },
	{ groupname: 'Indian Ocean', value: 'Indian/Cocos', label: 'Cocos', notes: '+06:30' },
	{ groupname: 'Indian Ocean', value: 'Indian/Comoro', label: 'Comoro', notes: '+03:00' },
	{ groupname: 'Indian Ocean', value: 'Indian/Kerguelen', label: 'Kerguelen', notes: '+05:00' },
	{ groupname: 'Indian Ocean', value: 'Indian/Mahe', label: 'Mahe', notes: '+04:00' },
	{ groupname: 'Indian Ocean', value: 'Indian/Maldives', label: 'Maldives', notes: '+05:00' },
	{ groupname: 'Indian Ocean', value: 'Indian/Mauritius', label: 'Mauritius', notes: '+04:00' },
	{ groupname: 'Indian Ocean', value: 'Indian/Mayotte', label: 'Mayotte', notes: '+03:00' },
	{ groupname: 'Indian Ocean', value: 'Indian/Reunion', label: 'Reunion', notes: '+04:00' },
	{ groupname: 'Mexico', value: 'Mexico/BajaNorte', label: 'BajaNorte', notes: '-08:00' },
	{ groupname: 'Mexico', value: 'Mexico/BajaSur', label: 'BajaSur', notes: '-07:00' },
	{ groupname: 'Mexico', value: 'Mexico/General', label: 'General', notes: '-06:00' },
	{ groupname: 'Pacific', value: 'Pacific/Apia', label: 'Apia', notes: '+13:00' },
	{ groupname: 'Pacific', value: 'Pacific/Auckland', label: 'Auckland', notes: '+12:00' },
	{ groupname: 'Pacific', value: 'Pacific/Chatham', label: 'Chatham', notes: '+12:45' },
	{ groupname: 'Pacific', value: 'Pacific/Chuuk', label: 'Chuuk', notes: '+10:00' },
	{ groupname: 'Pacific', value: 'Pacific/Easter', label: 'Easter', notes: '-06:00' },
	{ groupname: 'Pacific', value: 'Pacific/Efate', label: 'Efate', notes: '+11:00' },
	{ groupname: 'Pacific', value: 'Pacific/Enderbury', label: 'Enderbury', notes: '+13:00' },
	{ groupname: 'Pacific', value: 'Pacific/Fakaofo', label: 'Fakaofo', notes: '+13:00' },
	{ groupname: 'Pacific', value: 'Pacific/Fiji', label: 'Fiji', notes: '+12:00' },
	{ groupname: 'Pacific', value: 'Pacific/Funafuti', label: 'Funafuti', notes: '+12:00' },
	{ groupname: 'Pacific', value: 'Pacific/Galapagos', label: 'Galapagos', notes: '-06:00' },
	{ groupname: 'Pacific', value: 'Pacific/Gambier', label: 'Gambier', notes: '-09:00' },
	{ groupname: 'Pacific', value: 'Pacific/Guadalcanal', label: 'Guadalcanal', notes: '+11:00' },
	{ groupname: 'Pacific', value: 'Pacific/Guam', label: 'Guam', notes: '+10:00' },
	{ groupname: 'Pacific', value: 'Pacific/Honolulu', label: 'Honolulu', notes: '-10:00' },
	{ groupname: 'Pacific', value: 'Pacific/Johnston', label: 'Johnston', notes: '-10:00' },
	{ groupname: 'Pacific', value: 'Pacific/Kiritimati', label: 'Kiritimati', notes: '+14:00' },
	{ groupname: 'Pacific', value: 'Pacific/Kosrae', label: 'Kosrae', notes: '+11:00' },
	{ groupname: 'Pacific', value: 'Pacific/Kwajalein', label: 'Kwajalein', notes: '+12:00' },
	{ groupname: 'Pacific', value: 'Pacific/Majuro', label: 'Majuro', notes: '+12:00' },
	{ groupname: 'Pacific', value: 'Pacific/Marquesas', label: 'Marquesas', notes: '-09:30' },
	{ groupname: 'Pacific', value: 'Pacific/Midway', label: 'Midway', notes: '-11:00' },
	{ groupname: 'Pacific', value: 'Pacific/Nauru', label: 'Nauru', notes: '+12:00' },
	{ groupname: 'Pacific', value: 'Pacific/Niue', label: 'Niue', notes: '-11:00' },
	{ groupname: 'Pacific', value: 'Pacific/Norfolk', label: 'Norfolk', notes: '+11:30' },
	{ groupname: 'Pacific', value: 'Pacific/Noumea', label: 'Noumea', notes: '+11:00' },
	{ groupname: 'Pacific', value: 'Pacific/Pago_Pago', label: 'Pago Pago', notes: '-11:00' },
	{ groupname: 'Pacific', value: 'Pacific/Palau', label: 'Palau', notes: '+09:00' },
	{ groupname: 'Pacific', value: 'Pacific/Pitcairn', label: 'Pitcairn', notes: '-08:00' },
	{ groupname: 'Pacific', value: 'Pacific/Pohnpei', label: 'Pohnpei', notes: '+11:00' },
	{ groupname: 'Pacific', value: 'Pacific/Ponape', label: 'Ponape', notes: '+11:00' },
	{ groupname: 'Pacific', value: 'Pacific/Port_Moresby', label: 'Port Moresby', notes: '+10:00' },
	{ groupname: 'Pacific', value: 'Pacific/Rarotonga', label: 'Rarotonga', notes: '-10:00' },
	{ groupname: 'Pacific', value: 'Pacific/Saipan', label: 'Saipan', notes: '+10:00' },
	{ groupname: 'Pacific', value: 'Pacific/Samoa', label: 'Samoa', notes: '-11:00' },
	{ groupname: 'Pacific', value: 'Pacific/Tahiti', label: 'Tahiti', notes: '-10:00' },
	{ groupname: 'Pacific', value: 'Pacific/Tarawa', label: 'Tarawa', notes: '+12:00' },
	{ groupname: 'Pacific', value: 'Pacific/Tongatapu', label: 'Tongatapu', notes: '+13:00' },
	{ groupname: 'Pacific', value: 'Pacific/Truk', label: 'Truk', notes: '+10:00' },
	{ groupname: 'Pacific', value: 'Pacific/Wake', label: 'Wake', notes: '+12:00' },
	{ groupname: 'Pacific', value: 'Pacific/Wallis', label: 'Wallis', notes: '+12:00' },
	{ groupname: 'Pacific', value: 'Pacific/Yap', label: 'Yap', notes: '+10:00' },
	{ groupname: 'US', value: 'US/Alaska', label: 'Alaska', notes: '-09:00' },
	{ groupname: 'US', value: 'US/Aleutian', label: 'Aleutian', notes: '-10:00' },
	{ groupname: 'US', value: 'US/Arizona', label: 'Arizona', notes: '-07:00' },
	{ groupname: 'US', value: 'US/Central', label: 'Central', notes: '-06:00' },
	{ groupname: 'US', value: 'US/East-Indiana', label: 'East Indiana', notes: '-05:00' },
	{ groupname: 'US', value: 'US/Eastern', label: 'Eastern', notes: '-05:00' },
	{ groupname: 'US', value: 'US/Hawaii', label: 'Hawaii', notes: '-10:00' },
	{ groupname: 'US', value: 'US/Indiana-Starke', label: 'Indiana-Starke', notes: '-06:00' },
	{ groupname: 'US', value: 'US/Michigan', label: 'Michigan', notes: '-05:00' },
	{ groupname: 'US', value: 'US/Mountain', label: 'Mountain', notes: '-07:00' },
	{ groupname: 'US', value: 'US/Pacific', label: 'Pacific', notes: '-08:00' },
	{ groupname: 'US', value: 'US/Samoa', label: 'Samoa', notes: '-11:00' },
	{ groupname: 'Other', value: 'Arctic/Longyearbyen', label: 'Arctic - Longyearbyen', notes: '+01:00' },
	{ groupname: 'Other', value: 'Cuba', label: 'Cuba', notes: '-05:00' },
	{ groupname: 'Other', value: 'Egypt', label: 'Egypt', notes: '+02:00' },
	{ groupname: 'Other', value: 'Eire', label: 'Eire', notes: '+00:00' },
	{ groupname: 'Other', value: 'Hongkong', label: 'Hong Kong', notes: '+08:00' },
	{ groupname: 'Other', value: 'Iceland', label: 'Iceland', notes: '+00:00' },
	{ groupname: 'Other', value: 'Iran', label: 'Iran', notes: '+03:30' },
	{ groupname: 'Other', value: 'Israel', label: 'Israel', notes: '+02:00' },
	{ groupname: 'Other', value: 'Japan', label: 'Japan', notes: '+09:00' },
	{ groupname: 'Other', value: 'Kwajalein', label: 'Kwajalein', notes: '+12:00' },
	{ groupname: 'Other', value: 'Libya', label: 'Libya', notes: '+02:00' },
	{ groupname: 'Other', value: 'Navajo', label: 'Navajo', notes: '-07:00' },
	{ groupname: 'Other', value: 'Poland', label: 'Poland', notes: '+01:00' },
	{ groupname: 'Other', value: 'Portugal', label: 'Portugal', notes: '+00:00' },
	{ groupname: 'Other', value: 'Singapore', label: 'Singapore', notes: '+08:00' },
	{ groupname: 'Other', value: 'Turkey', label: 'Turkey', notes: '+02:00' },
	{ groupname: 'Other', value: 'Zulu', label: 'Zulu', notes: '+00:00' }
];