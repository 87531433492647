<script>

	import { fly } from 'svelte/transition';

	export let ref;
	export let value;
	export let options;
	export let disabled;

	export let nullOption = '';
	export let showNull = true;

	let open = false;

	function openOptions() {
		if (!disabled) {
			open = true;
		}
	}

	function toggleOptions() {
		if (!disabled) {
			open = !open;
		}
	}

	function closeOptions() {
		open = false;
	}

	let placeholder;
	$: placeholder = getNameFromValue(value);

	function getNameFromValue(v) {
		// console.log('getNameFromValue', v);
		if (!v) return nullOption;
		for (const o of options) {
			if (o.value == v) {
				return o.label;
			}
		}
		return nullOption;
	}

	let filterText = '';
	let filtered;

	$: if (options || !open) filterText = '';
	$: filtered = filterOptions(filterText);

	function filterOptions(txt) {
		let opts = [];
		for (const o of options) {
			if ((txt == '') || (o.label.toLowerCase().includes(txt.toLowerCase()))) {
				opts.push(o);
			}
		}
		return opts.length ? opts : options;
	}


	let highlighted = -1;
	let hovering = false;

	$: if (!open) {
		highlighted = -1;
		hovering = false;
	}

	function handleOptionClick(e) {
		setValue(e.target.dataset.value);
	}

	function setValue(v) {
		if (v === undefined) v = null;
		value = v;
		open = false;
		highlighted = -1;
	}

	let newFilter = true;
	let newFilterTimeout;

	function handleKeyDown(e) {
		switch (e.key) {
			case "ArrowDown":
				e.preventDefault();
				openOptions();
				if (!hovering && (highlighted != filtered.length)) {
					highlighted++;
					if ((highlighted == 0) && (!showNull)) {
						highlighted = 1;
					}
					setSheetScroll();
				}
				break;
			case "ArrowUp":
				e.preventDefault();
				openOptions();
				if (!hovering) {
					if (highlighted == -1) {
						highlighted = filtered.length;
					} else if (highlighted != (showNull ? 0 : 1)) {
						highlighted--;
					}
					setSheetScroll();
				}
				break;
			case "Enter":
				e.preventDefault();
				if (highlighted == -1) {
					openOptions();
				} else if (highlighted == 0) {
					setValue(undefined);
				} else if (filtered[highlighted - 1]) {
					setValue(filtered[highlighted - 1].value);
				}
				break;
			case " ":
				e.preventDefault();
				if (highlighted == -1) {
					openOptions();
				} else if (highlighted == 0) {
					setValue(undefined);
				} else if (filtered[highlighted - 1]) {
					setValue(filtered[highlighted - 1].value);
				}
				break;
			case "Escape":
				e.preventDefault();
				if (filtered.length != options.length) {
					filterText = '';
				} else {
					closeOptions();
					highlighted = -1;
				}
				break;
			default:
				if ((e.which >= 48) && (e.which <= 90)) {
					e.preventDefault();
					openOptions();
					clearTimeout(newFilterTimeout);
					highlighted = -1;
					if (newFilter) {
						filterText = String.fromCharCode(e.which);
						newFilter = false;
					} else {
						filterText = filterText + String.fromCharCode(e.which);
					}
					newFilterTimeout = setTimeout(function(){
						newFilter = true;
					}, 800);
					break;
				}
		}
	}

	let container;
	let sheet;
	let sheetOverflow = false;

	$: if (sheet) {
		sheetOverflow = opensInOverflow();
	}

	function setSheetScroll() {
		const lit = document.getElementById(ref + '_opt_' + highlighted);
		if (lit) {
			const scroll = sheet.scrollTop;
			const sheetHeight = sheet.offsetHeight;
			const litTop = lit.offsetTop;
			const litHeight = lit.offsetHeight;
			// console.log(litTop, scroll, (sheetHeight - scroll));
			if ((litTop + litHeight) > (sheetHeight - scroll)) {
				const newScroll = litTop;
				// console.log(newScroll);
				sheet.scrollTop = newScroll;
			} else if (litTop < scroll) {
				const newScroll = litTop;
				// console.log(newScroll);
				sheet.scrollTop = newScroll;
			}
		}
	}

	function getPanel(el) {
		for (; el; el = el.offsetParent) {
			if (el.classList.contains('form')) {
				return el;
			}
		}
	}

	function abstop(el, boundary) {
		let y = 0;
		for (; el; el = el.offsetParent) {
			y += el.offsetTop;
			if (boundary && (el == boundary)) break;
		}
		return y;
	}

	function opensInOverflow() {
		const panel = getPanel(container);
		const scroll = panel ? panel.scrollTop : 0;
		const top = abstop(container, panel) - scroll;
		const panelHeight = panel ? panel.offsetHeight : 0;
		const containerHeight = container.offsetHeight;
		const sheetHeight = sheet.offsetHeight;
		const absBottom = (top + containerHeight + sheetHeight);
		const viewportOffset = container.getBoundingClientRect();
		const viewportBottom = viewportOffset.bottom + sheetHeight;
		const out = (absBottom > panelHeight) || (viewportBottom > (window.innerHeight || document.documentElement.clientHeight));
		// console.log(scroll, top, panelHeight, containerHeight, sheetHeight, absBottom, viewportBottom, window.innerHeight, out);
		return out;
	}

	$: if (disabled) {
		closeOptions();
	}

</script>

<style>

	.select {
		position: relative;
	}

	.select button {
		width: 100%;
		text-align: left;
		position: relative;
		box-sizing: border-box;
		border: 1px solid transparent;
		border-color: var(--blend-40);
		border-radius: 3px;
		padding: 0.6rem 1rem 0.6rem 1rem;
		line-height: 1;
		background: var(--blend-05);
		color: var(--textColor);
		margin: 0;
		display: block;
		min-height: calc(1.8em + 2px);
	}

	/*.select.disabled button {
		opacity: 0.6;
	}*/

	.select button span {
		display: inline-block;
		min-height: 0.9em;
	}

	.select button svg {
		position: absolute;
		top: 50%;
		transform: translateY(-0.5em);
		right: 0.5em;
		width: 1em;
		height: 1em;
	}

	.select.subtle button svg {
		width: 1em;
		height: 1em;
		transform: translateY(-0.5em);
	}

	/*.select.disabled button svg {
		opacity: 0.7;
	}*/

	.select button path {
		fill: var(--blend-60);
		transition: fill 0.2s ease-in-out;
	}

	.select button.open {
		border-color: var(--textColor);
	}

	.select button.open path {
		fill: var(--textColor);
	}

	/*#aznr .azn_block:not(.disabled) .Select button:focus {
		outline: none;
		border-color: var(--blend-60);
	}*/

	.select button:active {
		background: var(--blend-05);
	}

	/*#aznr .field.problem .Select button {
		border-color: var(--red);
	}*/

	.select .null {
		color: var(--blend-40)
	}

	.select .options {
		position: absolute;
		left: 50%;
		top: calc(100% + 4px);
		width: calc(100% - 10px);
		min-width: 100px;
		transform: translateX(-50%);
		background: var(--panelColor);
		box-shadow: 0 1px 6px -1px rgba(0,0,0,0.3);
		z-index: 10000;
		max-height: 200px;
		overflow-y: scroll;
		border-radius: 4px;
	}

	.select .options.low {
		top: auto;
		bottom: calc(100% + 4px);
	}

	.select .options ul {
		margin: 0;
		padding: 0;
	}

	.select .options li {
		list-style: none;
		margin: 0;
		padding: 0.6em 0.8rem;
		text-align: left;
		font-size: 0.8rem;
		min-height: 1em;
		user-select: none;
		cursor: pointer;
		color: var(--textColor);
	}

	.select .options li.highlighted {
		background: var(--blend-10);
	}

	.select .options li.groupname {
		font-weight: 600;
		color: var(--blend-80);
		text-transform: uppercase;
		cursor: default;
	}

	.select .options li.hasGroupname {
		padding-left: 2em;
	}

</style>

<div class="select" bind:this={container}>
	<button
		type="button"
		id={ref}
		class:open
		class:null={placeholder == nullOption}
		on:click={toggleOptions}
		on:blur={closeOptions}
		on:keydown|stopPropagation={handleKeyDown}
		role="listbox"
		aria-haspopup="true"
		aria-expanded={open}
		aria-owns={open ? ref + '_options' : undefined}
		aria-activedescendant={(highlighted == -1) ? undefined : ref + '_opt_' + highlighted}
	>
		<span>{ placeholder }</span>
		<svg viewBox="0 0 100 100"><path d="M47.42 3.23l-19.27 33.38c-1.05 1.83.26 4.11 2.37 4.11h38.54c2.11 0 3.43-2.28 2.37-4.11l-19.27-33.38c-1.05-1.82-3.69-1.82-4.74 0zM47.84 96.77l-19.27-33.38c-1.05-1.83.26-4.11 2.37-4.11h38.54c2.11 0 3.43 2.28 2.37 4.11l-19.27 33.38c-1.05 1.82-3.69 1.82-4.74 0z"/></svg>
	</button>
	{#if open}
		<div
			id="{ref}_options"
			class="options"
			bind:this={sheet}
			class:low={sheetOverflow}
			transition:fly|local={{ duration: 200, y: -3 }}
			on:mouseover={() => {hovering = true}}
			on:focus={() => {hovering = true}}
			on:mouseout={() => {hovering = false}}
			on:blur={() => {hovering = false}}
		>
			<ul>
				{#if showNull}
					<li
						id="{ref}_opt_0"
						data-value={null}
						class="null"
						class:highlighted={highlighted == 0}
						role="option"
						aria-selected={value == null}
						on:click={handleOptionClick}
						on:keydown|stopPropagation={handleKeyDown}
						on:mouseover={() => {highlighted = 0}}
						on:focus={() => {highlighted = 0}}
					>
						{nullOption}
					</li>
				{/if}
				{#each filtered as o, i (o.value)}
					{#if (o.groupname && ((i == 0) || (o.groupname != filtered[i-1].groupname)))}
						<li class="groupname">{o.groupname}</li>
					{/if}
					<li
						id="{ref}_opt_{i+1}"
						data-value={o.value}
						class:highlighted={highlighted == i + 1}
						class:hasGroupname={o.groupname}
						role="option"
						aria-selected={value == o.value}
						on:click={handleOptionClick}
						on:keydown|stopPropagation={handleKeyDown}
						on:mouseover={() => {highlighted = i + 1}}
						on:focus={() => {highlighted = i + 1}}
					>
						{o.label}
					</li>
				{/each}
			</ul>
		</div>
	{/if}
</div>