<script>
</script>

<style>

	.alert {
		display: flex;
		gap: 0.6rem;
		font-size: 0.875rem;
		font-weight: bold;
		margin: 1rem 0;
	}

	img {
		width: 1.6rem;
	}

</style>

<p class="alert">
	<img src="/alert.svg" alt=""/>
	<span>
		<slot/>
	</span>
</p>