<script>

	import { fade, slide } from 'svelte/transition';

	import cssVars from 'svelte-css-vars';

	import Message from './lib/Message.svelte';
	import DatePicker from './lib/DatePicker.svelte';
	import SlotPicker from './lib/SlotPicker.svelte';
	import Details from './lib/Details.svelte';

	import { autoblur } from './lib/autoblur.js';
	import { postServerData } from './lib/prelude.js';

	let chosenDate;
	let chosenSlot;
	let details = {};
	let screen = 1;
	let dataOK = false;
	let buttonLabel = 'Next';

	let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

	const styleVars = {
		"red": "#e2261e",
		"bgColor": "#fff",
		"red-120": "#b51e18",
		"redText": "#fff",
		"blend-05": "#f6f8f9",
		"blend-10": "#edf1f3",
		"blend-20": "#dce3e8",
		"blend-40": "#b9c8d1",
		"blend-60": "#95acb9",
		"blend-80": "#7291a2",
		"blend-90": "#618397",
		"blend-120": "#3f5e6f",
		"textColor": "#101820",
		"panelColor": "#fff",
		"accentColor": "#e5077e",
		"accentBlend-05": "#fef2f9",
		"accentBlend-10": "#fde6f2",
		"accentBlend-20": "#facce5",
		"accentBlend-40": "#f599cb",
		"accentBlend-60": "#f066b2",
		"accentBlend-80": "#eb3398",
		"accentBlend-90": "#e91a8b",
		"accentBlend-120": "#b80065",
		"buttonTextColor": "#fff"
	};

	async function nextScreen() {
		if (dataOK) {

			if (screen == 3) {

				const json = JSON.stringify({
					dt: chosenSlot,
					tz: tz,
					name: details.name,
					company: details.company,
					email: details.email,
					mobile: details.mobile
				});

				// TODO: catch errors here...
				await postServerData('marketing/meet', { json: json });

			}

			screen = screen + 1;
			// screen = 4;

		}
	}

	function prevScreen() {
		screen = screen - 1;
	}

	$: buttonLabel = (screen == 3) ? 'Confirm' : 'Next';

	// $: console.log(tz);

</script>

<style>
	main {
		position: relative;
		background: #fff;
		border-radius: 4px;
		width: 60rem;
		max-width: 90vw;
		padding: 1.4rem;
		box-sizing: border-box;
		box-shadow: 0 1px 5px -2px rgba(0,0,0,0.2);
	}
	.logo {
		width: 8rem;
	}
	main > div {
		display: flex;
		height: 100%;
	}
	.masthead {
		padding-bottom: 1rem;
		border-bottom: 1px solid #edf1f3;
		justify-content: left;
	}
	.masthead + div > div {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
	.details {
		flex: 1 1 auto;
		padding-right: 1.4rem;
		min-height: 24.6rem;
	}
	.form {
		position: relative;
		border-left: 1px solid #edf1f3;
		width: 22rem;
		padding-left: 1.4rem;
		display: grid;
	}
	h2 {
		font-family: "Gilroy-Bold", sans-serif;
		margin-bottom: 1.6rem;
	}
	.actions {
		padding-top: 1rem;
		border-top: 1px solid #edf1f3;
		justify-content: right;
		height: 2rem;
		opacity: 1;
		transition: opacity 0.6s ease;
	}
	.actions.fin {
		opacity: 0;
	}
	button {
		background: #4f758b;
		color: #fff;
		border: 0;
		border-radius: 4px;
		padding: 0.6rem 2rem;
		line-height: 1;
		font-weight: 600;
		min-width: 10.35rem;
		cursor: pointer;
		transition: background-color 0.2s ease;
	}
	button.green {
		background: #77b82a;
	}
	button:hover,
	button:focus,
	button.green:hover,
	button.green:focus {
		background: #e5077e;
	}
	button.disabled,
	button.disabled:hover {
		background: #b9c8d1;
		cursor: default;
	}
	button + button {
		margin-left: 1rem;
	}
	.message {
		display: grid;
	}
	@media only screen and (max-width: 930px) {
		main > div {
			flex-wrap: wrap;
			justify-content: center;
		}
		.details {
			flex: 0 0 100%;
			padding: 0 0 1.4rem 0;
			min-height: unset;
		}
		main > div > .form {
			border-top: 1px solid #e1e3e5;
			padding: 1.4rem 0 1rem 0;
			border-left: none;
			min-height: unset;
		}
	}
</style>

<main use:cssVars="{styleVars}">
	<div class="masthead">
		{#if screen == 4}
			<p class="logo"><a href="https://www.attendzen.io/"><img src="/attendzen.svg" alt="AttendZen"/></a></p>
		{:else}
			<p class="logo"><img src="/attendzen.svg" alt="AttendZen"/></p>
		{/if}
	</div>
	<div>
		<div class="details">
			<h2>Book a call with Ian Dickie</h2>
			<div class="message">
				<Message {screen} {chosenDate} {chosenSlot} {tz}/>
			</div>
		</div>
		{#if screen < 4}
			<form class="form" id="form" on:submit|preventDefault={nextScreen}>
				{#if screen == 3}
					<Details bind:details bind:dataOK />
				{:else if screen == 2}
					<SlotPicker bind:dataOK bind:tz bind:chosenSlot {chosenDate} />
				{:else}
					<DatePicker bind:dataOK bind:selected={chosenDate} />
				{/if}
			</form>
		{/if}
	</div>
	<div class="actions" class:fin={screen == 4}>
		{#if screen < 4}
			<div out:fade>
				{#if screen > 1}
					<button
						transition:fade
						type="button"
						on:click={prevScreen}
						use:autoblur
					>
						Previous
					</button>
				{/if}
				<button
					type="submit"
					form="form"
					class="green"
					use:autoblur
					class:disabled={!dataOK}
					disabled={!dataOK}
				>
					{buttonLabel}
				</button>
			</div>
		{/if}
	</div>
</main>