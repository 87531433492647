<script>

	import { onMount, tick } from 'svelte';
	import { fly } from 'svelte/transition';

 	import Field from './Field.svelte';

	export let dataOK;
	export let details = {};

	let emailErr;

	onMount(async () => {
		await tick();
		document.getElementById('name').focus();
	});

	$: dataOK = (details.name && details.company && details.email && !emailErr) ? true : false;

</script>

<style>
	.wrapper {
		grid-area: 1/1/2/2;
/*		height: 24.6rem;*/
		width: 22rem;
	}
</style>

<div class="wrapper" transition:fly={{ y: 10, duration: 200 }}>

	<Field
		ref="name"
		bind:value={details.name}
		component="TextField"
		label="Your name"
	/>

	<Field
		ref="company"
		bind:value={details.company}
		component="TextField"
		label="Your company"
	/>

	<Field
		ref="email"
		bind:value={details.email}
		component="EmailField"
		label="Your email address"
		bind:err={emailErr}
	/>

	<Field
		ref="mobile"
		bind:value={details.mobile}
		component="TextField"
		label="Your mobile number"
		notes="I’ll only call / text you if you’re having difficulty joining the meeting for any reason. There will be no annoying sales calls!"
	/>

</div>