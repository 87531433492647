<script>

	import { onMount, tick } from 'svelte';
	import { fly } from 'svelte/transition';

	import dayjs from 'dayjs/esm/index.js';

	import { InlineCalendar } from 'svelte-calendar';

	const today = new Date();
	const start = dayjs().add(1, 'day').toDate();
	const end = dayjs().add(3, 'month').toDate();

	export let dataOK;
	export let selected = start;

	const theme = {
		"calendar": {
			"width": "22rem",
			"maxWidth": "22rem",
			"legend": {
				"height": "34px"
			},
			"shadow": "none",
			"colors": {
				"text": {
					"primary": "#101820",
					"highlight": "#fff"
				},
				"background": {
					"primary": "transparent",
					"highlight": "transparent",
					"hover": "transparent"
				},
				"border": "transparent"
			},
			"font": {
				"regular": "1rem",
				"large": "4rem"
			},
			"grid": {
				"disabledOpacity": "0.2",
				"outsiderOpacity": "0.9"
			}
		}
	};

	// "background": {
	// 	"primary": "#fff",
	// 	"highlight": "#e5077e",
	// 	"hover": "#dce3e8"
	// },

	$: dataOK = selected ? true : false;

</script>

<style>
	div {
		grid-area: 1/1/2/2;
/*		height: 24.6rem;*/
	}
	div :global(.controls) {
		font-size: 1rem;
		border-bottom: 2px solid #edf1f3;
	}
	div :global(.legend) {
		font-size: 0.875rem;
	}
	div :global(.legend span) {
		position: relative;
	}
	div :global(.legend span:after) {
		content: "";
		position: absolute;
		bottom: -5px;
		left: 1px;
		right: 1px;
		height: 2px;
		background: #edf1f3;
	}
	div :global(.controls *) {
		height: 1.8rem;
	}
	div :global(.controls .button) {
		position: relative;
	}
	div :global(.controls .button:hover:before) {
		content: '';
		position: absolute;
		inset: 1px;
		background: #dce3e8;
		border-radius: 3px;
		z-index: -1;
		transition: background-color 100ms ease-out;
	}
	div :global(.button i) {
		width: 0.6rem;
		height: 0.6rem;
	}
	div :global(.button i.left) {
		transform: translateX(0.2rem) rotate(135deg);
	}
	div :global(.button i.right) {
		transform: translateX(-0.2rem) rotate(-45deg);
	}
	div :global(.grid > a) {
		position: relative;
		transition: all 100ms ease-out;
	}
	div :global(.grid > a:before) {
		content: '';
		position: absolute;
		inset: 1px;
		background: #f6f8f9;
		border-radius: 3px;
		z-index: -1;
		transition: background-color 100ms ease-out;
	}
	div :global(.grid > a:nth-child(even):before) {
		background: #fcfdfd;
	}
	div :global(.grid > a:not(.disabled):hover:before) {
		background: #dce3e8;
	}
	div :global(.grid > a.selected:before) {
		background: #e5077e !important;
	}
</style>

<div transition:fly={{ y: 10, duration: 200 }}>
	<InlineCalendar {start} {end} bind:selected {theme} />
</div>
