<script>

	import isEmail from 'validator/es/lib/isEmail';

	export let ref;
	export let value = '';
	export let err;
	export let inputmode = 'email';
	export let inFocus;
	export let disabled;

	function handleFocus() {
		inFocus = true;
	}

	function handleBlur() {
		inFocus = false;
	}

	function validate() {
		if (isEmail(value)) {
			err = undefined;
		} else {
			err = 'Please enter a valid email address.';
		}
	}

</script>

<style>
	input {
		box-sizing: border-box;
		width: 100%;
		border: 1px solid var(--blend-40);
		background: var(--blend-05);
		color: var(--textColor);
		padding: 0.8rem 1rem;
		line-height: 1;
		font-size: 0.8rem;
		border-radius: 3px;
	}
	input.err {
		border-color: var(--red);
	}
	input:focus {
		outline: none;
		border-color: var(--textColor);
	}
</style>

<input
	type="text"
	id={ref}
	bind:value={value}
	{inputmode}
	autocomplete="off"
	class:err
	on:focus={handleFocus}
	on:blur={handleBlur}
	on:input={validate}
	{disabled}
/>