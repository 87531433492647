import axios from 'axios';

let baseURL = 'https://api.attendzen.io/';
let debug = false;

if ((window.location.hostname == 'localhost') || window.location.hostname.startsWith('192.168') || (window.location.hostname == '127.0.0.1')) {
	baseURL = 'https://api-dev.attendzen.io/';
	debug = true;
}

const prelude = axios.create({
	baseURL: baseURL,
	timeout: 100000,
	withCredentials: false
});

export const getServerData = async (endpoint, params) => {

	try {

		if (debug) console.log('GET', endpoint, new Date().toISOString());

		const response = await prelude({
			url: endpoint,
			method: "GET",
			params: params
		});

		if (debug) console.log('data', 'GET', endpoint, response.data, new Date().toISOString());

		return response.data;

	} catch (err) {

		if (debug) {
			console.log(err.toJSON());
			console.log(err.response.status);
		}

		return null;

	}

}

export const postServerData = async (endpoint, params) => {

	let formData = new FormData();

	if (params) {
		for (let [key, value] of Object.entries(params)) {
			formData.append(key, value);
		}
	}

	try {

		if (debug) console.log('POST', endpoint, new Date().toISOString());

		const response = await prelude({
			url: endpoint,
			method: "POST",
			data: formData
		});

		if (debug) console.log('data', 'POST', endpoint, response.data, new Date().toISOString());

		return response.data;

	} catch (err) {

		if (debug) {
			console.log(err.toJSON());
			console.log(err.response.status);
		}

		return null;

	}

}