<script>

	import { fade, fly, slide } from 'svelte/transition';

	// NB: We need this import format for Vite
	// I've patched Svelte Calendar to match
	import dayjs from 'dayjs/esm/index.js';
	import utc from 'dayjs/esm/plugin/utc';
	import timezone from 'dayjs/esm/plugin/timezone';
 	
	import { timezones } from './timezones.js';

	dayjs.extend(utc);
	dayjs.extend(timezone);

	export let screen;
	export let chosenDate;
	export let chosenSlot;
	export let tz;

	let team;
	let tzLabel;

	function setTzLabel() {
		for (const z of timezones) {
			if (z.value == tz) {
				tzLabel = z.label;
				break;
			}
		}
	}

	$: if (tz) {
		setTzLabel();
	}

	$: if (team) {
		team.classList.add('show');
	}

</script>

<style>

	div {
		grid-area: 1/1/2/2;
	}

	p {
		font-size: 0.875rem;
	}

	p + p {
		margin-top: 1rem;
	}

	.sig {
		margin-top: 2rem;
	}

	dl {
		display: flex;
		gap: 1.6rem;
		font-size: 0.875rem;
		margin: 0;
	}

	dl + dl {
		margin-top: 1rem;
	}

	dt {
		font-weight: bold;
		flex: 0 0 4rem;
	}

	dd {
		margin: 0;
		padding: 0;
	}

	.fin {
		margin-top: 2rem;
	}

	:global(img.team) {
		position: absolute;
		right: 1rem;
		bottom: 0;
		width: 46vw;
		z-index: 0;
		opacity: 0;
		transform: scale(0.95);
		transform-origin: 50% 100%;
		transition: opacity 0.6s ease-in, transform 2s ease-out;
	}

	:global(img.team.show) {
		opacity: 1;
		transform: scale(1);
	}

	@media only screen and (max-width: 930px) {
		img.team {
			position: relative;
			display: block;
			top: 5.5rem;
			left: 50%;
			width: auto;
			max-width: 75vw;
			transform: translateX(-50%);
		}
	}

</style>

{#if screen == 1}
	<div transition:fly={{ y: 10, duration: 200 }}>
		<div out:slide={{ duration: 200 }}>
			<p>Hi!</p> 
			<p>Schedule a 30 min call to talk to me about your events and how you’d like to harness technology to achieve your goals.</p>
			<p>I’ll be happy to help in any way I can, and to answer your questions about the AttendZen platform.</p>
			<p>Then, if you’re interested, we can set up a customised demo and free trial for you and your team.</p>
			<p class="sig">
				<img alt="Ian" src="https://img.attendzen.io/5ffdebf706b7f4939a198fda91a811edcebeef04.jpg" width="60">
			</p>
		</div>
	</div>
{:else}
	<div transition:fly={{ y: 10, duration: 200 }}>
		<div out:slide={{ duration: 200 }}>
			<dl>
				<dt>Date:</dt>
				<dd>{ dayjs.tz(chosenDate,'Europe/London').format('dddd D MMMM YYYY') }</dd>
			</dl>
			{#if screen > 2}
				<dl in:fly={{ y: 10, duration: 200 }} out:slide={{ duration: 200 }}>
					<dt>Time:</dt>
					<dd>{ dayjs.tz(chosenSlot,'Europe/London').tz(tz).format('h a') } {#if tzLabel}({tzLabel}){/if}</dd>
				</dl>
			{/if}
			{#if screen == 4}
				<div class="fin" in:fly={{ y: 10, duration: 200 }}>
					<p>Great! I look forward to speaking with you soon.</p>
			 		<p>I’ll send you a calendar invite by email shortly.</p>
				</div>
			{/if}
		</div>
	</div>
	{#if screen == 4}
		<img bind:this={team} class="team" src="/team.jpg" alt=""/>
	{/if}
{/if}