<script>

	import { onMount } from 'svelte';
	import { fade, fly } from 'svelte/transition';
	import { flip } from 'svelte/animate';
	import { quintOut } from 'svelte/easing';

	// NB: We need this import format for Vite
	// I've patched Svelte Calendar to match
	import dayjs from 'dayjs/esm/index.js';
	import utc from 'dayjs/esm/plugin/utc';
	import timezone from 'dayjs/esm/plugin/timezone';
 	
	import { timezones } from './timezones.js';

 	import Field from './Field.svelte';
 	import Alert from './Alert.svelte';

	import { getServerData } from './prelude.js';
	import { autoblur } from './autoblur.js';

	dayjs.extend(utc);
	dayjs.extend(timezone);

	export let dataOK;
	export let chosenDate;
	export let chosenSlot;
	export let tz;

	let slots = [];
	let visibleSlots = [];
	let bookedSlots = [];

	let fetched = false;

	// NB: slots on the server are **not** in UTC -- they're in Europe/London
	const dow = dayjs.tz(chosenDate, 'Europe/London').format('ddd');
	const weekend = ((dow == 'Sat') || (dow == 'Sun')) ? true : false;

	onMount(async () => {

		const df = dayjs.tz(chosenDate, 'Europe/London').format('YYYY-MM-DD');

		let slotsData = await getServerData('marketing/meet', {
			date: df
		});

		if (slotsData && slotsData.slots) {

			slots = slotsData.slots;
			fetched = true;

		}

	});

	function setVisibleSlots() {

		visibleSlots = [];

		for (let s of slots) {

			const dt = dayjs.tz(s.dt, 'Europe/London');
			const hr = dt.format('HH');

			if ((dow == 'Fri') && ((hr == 16) || (hr == 17))) {
				s.booked = true;
			}

			const dtz = dt.tz(tz);
			const hrz = dtz.format('HH');

			if (parseInt(hrz) < 6) continue;

			s.label = dtz.format('h a');

			visibleSlots.push(s);

		}

		visibleSlots = visibleSlots;

		for (const s of visibleSlots) {
			if (s.booked) {
				bookedSlots.push(s);
			}
		}

	}

	$: if ((slots && slots.length) || tz) {
		setVisibleSlots();
	}

	$: dataOK = chosenSlot ? true : false;

</script>

<style>
	.wrapper {
		grid-area: 1/1/2/2;
/*		height: 24.6rem;*/
		width: 22rem;
	}
	p {
		font-size: 0.6875rem;
		font-weight: bold;
		margin-top: 2rem;
	}
	.slots {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		justify-content: flex-start;
		margin-top: 1rem;
	}
	button {
		background: #fff;
		color: #4f758b;
		border: 1px solid #4f758b;
		border-radius: 4px;
		padding: 0.6rem 1rem;
		line-height: 1;
		font-weight: 600;
		flex: 0 0 calc(33.3% - 0.66rem);
		box-sizing: border-box;
		cursor: pointer;
	}
	button:hover,
	button:focus {
		color: #e5077e;
		border: 1px solid #e5077e;
	}
	button.selected {
		color: #e5077e;
		border: 1px solid #e5077e;
	}
	button.disabled {
		color: #b9c8d1;
		border: 1px solid #b9c8d1;
		cursor: default;
	}
</style>

{#if fetched}
	<div class="wrapper" transition:fly={{ y: 10, duration: 200 }}>

		{#if weekend || !visibleSlots.length || (visibleSlots.length == bookedSlots.length)}

			<Alert>
				Sorry, no slots are available on this date.
			</Alert>

		{:else}

			<Field
				ref="timezone"
				bind:value={tz}
				component="SelectList"
				label="Time zone:"
				options={timezones}
			/>

			<p>Please choose a slot:</p>

			<div class="slots">
				{#each visibleSlots as slot (slot.dt)}
					<button
						type="button"
						class:selected={slot.dt == chosenSlot}
						class:disabled={slot.booked}
						disabled={slot.booked}
						on:click={() => {chosenSlot = slot.dt}}
						in:fade={{ duration: 300 }}
						out:fade="{{ duration: 300 }}"
						animate:flip={{ duration: 300 }}
					>
						{slot.label}
					</button>
				{/each}
			</div>

		{/if}

	</div>
{/if}